import React from "react"
import { graphql } from 'gatsby'
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import SEO from "../components/seo";
import { SliceZones } from "../components/slices/slice-zones";
import LayoutFullPage from "../components/full-page/layout-full-page";
import Layout from "../components/full-page/layout";
import { ColorThemeContextProvider } from "../contexts/colorThemeContext";

const HomePage = ({ data, isPrismicPreview, pageContext: { lang } }) => {
  if (!data) {
    return null;
  }

  const document = data.prismicHome;

  const meta = [
    {
      name: `description`,
      content: document.data.meta_description.text,
    },
    {
      property: `og:title`,
      content: document.data.meta_title.text,
    },
    {
      property: `og:description`,
      content: document.data.meta_description.text,
    },
    {
      name: `twitter:description`,
      content: document.data.meta_description.text,
    },
    {
      name: `og:image`,
      content: document.data.share_image.url,
    },
  ]

  return (
    <>
      <SEO title={document.data.title.text} meta={meta} canonical_link={document.data.canonical_link?.url} />
      <ColorThemeContextProvider>
        <LayoutFullPage
          bottomArrow
          fullPageMobile={false}
          isPrismicPreview={isPrismicPreview}
          links={[
            {url: 'https://shop.claritycoat.com/collections/autoflex', label: "Shop"},
          ]}
          currentLanguage={lang}
          topNav={document.data.menu_links}
          footerNav={document.data.footer_links}
          blogListing={data.allPrismicBlogPost?.nodes}
        >
          <SliceZones slices={document.data.body} fullpage={true} />
        </LayoutFullPage>
      </ColorThemeContextProvider>
    </>
  )
};

export const query = graphql`
query homeQueryAndHomeQuery($lang: String) {
  prismicHome(lang: { eq: $lang }) {
    _previewable
    id
    type
    url
    lang
    alternate_languages {
      id
      type
      lang
      uid
    }
    data {
      menu_links {
        menu_link {
         url
         type
        }
        name {
          text
        }
      }
      footer_links {
        footer_link {
         url
         type
        }
        name {
          text
        }
      }
      title {
        text
      }
      meta_title {
        text
      }
      share_image {
        url
      }
      meta_description {
        text
      }
      canonical_link {
        url
      }
      body {
        ... on PrismicHomeDataBodyFullpageCta {
          id
          slice_type
          primary {
            theme
            cta_1_link {
              url
            }
            cta_1_title
            cta_2_link {
              url
            }
            cta_2_title
            heading {
              text
            }
            image {
              url
            }
            image_mobile {
              url
            }
            video_url {
              url
            }
            video_mobile_url {
              url
            }
            video_autoplay
            video_muted
            video_controls
            video_playsinline
            video_loop
            subheading {
              html
            }
          }
        }
        ... on PrismicHomeDataBodyFullpageFeatures {
          id
          slice_type
          primary {
            theme
            cta_title
            heading {
              text
            }
            image {
              url
            }
            image_mobile {
              url
            }
            video_url {
              url
            }
            video_mobile_url {
              url
            }
            video_autoplay
            video_muted
            video_controls
            video_playsinline
            video_loop
            subheading {
              html
            }
            cta_link {
              url
            }
          }
          items {
            subtitle {
              text
            }
            title1 {
              text
            }
          }
        }
        ... on PrismicHomeDataBodyImageGallery1 {
          id
          slice_type
          primary {
            theme
          }
          items {
            description {
              text
            }
            image {
              url
            }
            image_mobile {
              url
            }
            video_url {
              url
            }
            video_mobile_url {
              url
            }
            video_autoplay
            video_muted
            video_controls
            video_playsinline
            video_loop
            title1 {
              text
            }
          }
        }
        ... on PrismicHomeDataBodyImageGallery2 {
          id
          slice_type
          primary {
            theme
            title1 {
              text
            }
            description {
              text
            }
          }
          items {
            image {
              url
            }
            image_mobile {
              url
            }
            video_url {
              url
            }
            video_mobile_url {
              url
            }
            video_autoplay
            video_muted
            video_controls
            video_playsinline
            video_loop
          }
        }
        ... on PrismicHomeDataBodyFullpage2Columns {
          id
          items {
            cta_title
            cta_link {
              url
            }
          }
          primary {
            theme
            content {
              html
            }
            heading {
              text
            }
            image {
              url
            }
            image_mobile {
              url
            }
            video_url {
              url
            }
            video_mobile_url {
              url
            }
            video_autoplay
            video_muted
            video_controls
            video_playsinline
            video_loop
            image_position
            subheading {
              html
            }
          }
          slice_type
        }
        ... on PrismicHomeDataBodyFullpageImage {
          id
          primary {
            theme
            image {
              url
            }
            image_mobile {
              url
            }
            video_url {
              url
            }
            video_mobile_url {
              url
            }
            video_autoplay
            video_muted
            video_controls
            video_playsinline
            video_loop
          }
          slice_type
        }
      }
    }
  }
  
  allPrismicBlogPost(filter: {lang: {eq: $lang}}) {
    nodes {
      url
      data {
        title {
            text
        }
      }
    }
  }
}
`

export default withPrismicPreview(HomePage);